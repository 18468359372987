@import 'styles/vars';

.card {
    padding: 2rem;
    color: $primary-white;
    font-size: 1.6rem;
    border-radius: 4px;
    margin-bottom: 2rem;

    .cardTitle__container {
        .cardTitle {
            font-size: 1.2rem;
            margin-bottom: 0.5rem;
        }

        margin-bottom: 2rem;
    }

}

@media screen and (min-width:64em) {
    .card{
        display: grid;
        grid-template-columns: 0.5fr 1fr .5fr;
        gap: 2rem;

        .cardTitle__container {
            margin-bottom: 0;
        }
    }
}

.company--NAPA {
    .card {
        background: lighten($napa-primary-blue, 5);
    }
}

.company--AAG {
    .card {
        background: lighten($aag-primary-blue, 5);
    }
}