@import "../../styles/vars.scss";

body {
    overflow: auto !important;
}
.unauthorizedContainer {

    position: relative;
    height: 100%;

    .unauthorizedContainerPadding {
        padding: 2rem;
    }

    .unauthorizedContent {

        width: 480px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .unauthorizedLogo {
            margin: 0 0 6rem 0;
            text-align: center;
            img {
                max-height: 60px;  
            }
        }

    }

    h1 {
        color: $primary-white;
        font-weight: normal;
    }


    .input-container {
        border-color: none;
        // background: rgba(0,0,0,0.25);
        background: #081A56;
        // background: #091F67;

        .label {
            color: $primary-white;
        }

        input {
            color: $primary-white;
        }

    }

    label.checkbox {
        border-color: none;
        background: rgba(0,0,0,0.25);
    }

    .input-container.input-focus,
    .input-container.input-filled {
        .label {
            color: darken($primary-white, 30);
        }
    }

    .submitButton {
        background: $primary-yellow;
    }

    .submitButton:hover {
        background: darken($primary-yellow, 10);
    }

    .passwordTest {
        line-height: 20px;
        margin: .5rem 0;
        word-wrap: break-word;

        i {
            font-size: 21px;
        }
    }

    .passwordTest--passed i {
        color: $success-green;
    }

    .passwordTest--failed i {
        color: rgba(0,0,0,0.4);
    }

    .languageButton {
        border: none;
        background: none;
        outline: none;
        float: right;
        margin-top: 12px;
        text-decoration: underline;
        color: lighten($secondary-blue,20);
        cursor: pointer;
        padding: 0px;
    }

    .errorMessageContainer {
        background-color: #FFCACA !important;
    }

    .forgotPasswordLink {
        color: lighten($secondary-blue, 20);
        display: inline-block;
        position: relative;
        top: -10px;
    }

    .forgotPasswordLink:hover,
    .languageButton:hover {
        color: lighten($secondary-blue,10);
    }

}

@media screen and (max-width:35.5em) {
    .unauthorizedContainer {
    
        .unauthorizedContent {
            width: 100%;
        }
    }
}

.company--NAPA {
    .unauthorizedContainer {

        .unauthorizedContent {
            // background: $napa-primary-blue;
            background: #091F67;
            border-radius: 4px;
            box-shadow: 0px 3px 12px rgba(0,0,0,0.4);

            .unauthorizedContainerPadding {
                padding: 6rem 5rem;
            }

        }

        .submitButton {
            background: $napa-primary-yellow;
            color: $napa-primary-blue;
        }

        .submitButton:hover {
            background: darken($napa-primary-yellow, 10);
        }

        .passwordTest {
            color: lighten($napa-primary-blue, 70);
        }
    }
}

.company--AAG {
    .unauthorizedContainer {

        .unauthorizedContent {
            background: #fff;
            border-radius: 4px;
            box-shadow: 0px 3px 12px rgba(0,0,0,0.4);

            .input-container {
                background: #fff;

                .label {
                    color: $aag-primary-blue;
                }

                input {
                    color: $aag-primary-blue;
                }
            }

            .input-container.input-focus,
            .input-container.input-filled {
                .label {
                    color: rgba(0,0,0,0.6);
                }
            }

            .unauthorizedContainerPadding {
                padding: 6rem 5rem;
            }
        }

        .submitButton {
            background: $aag-primary-blue;
            color: $aag-primary-white;
        }

        .submitButton:hover {
            background: darken($aag-primary-blue, 10);
        }

        .passwordTest {
            color: $aag-primary-blue;
        }
    }
}