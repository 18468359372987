@import '../../../styles/vars.scss';

.submitButton {
	display: block;
	border-radius: 4px;
	border: none;
	padding: 1.5rem 3rem;
	color: $primary-white;
	cursor: pointer;
	font-family: inherit;
	font-size: 1.6rem;
	text-decoration: none;
	margin-bottom: 2rem;
}

.submitButton--fullWidth {
	width: 100%;
}

.submitButton--yellow {
	background: $primary-yellow;
	color: darken($primary-yellow, 40);

	&:hover {
		// color: @primary-white;
		background: darken($primary-yellow, 5) !important;
	}
}

.submitButton--green {
	background: $success-green;
	color: $primary-white;
}
.submitButton--green:hover {
	color: $primary-white;
	background: darken($success-green, 10) !important;
}

.submitButton--red {
	background: $primary-red;
	color: $primary-white;
}
.submitButton--red:hover {
	color: $primary-white;
	background: darken($primary-red, 10) !important;
}

.submitButton:hover {
	background: $secondary-blue;
}

.submitButton:disabled {
	background: $light-gray;
	color: rgba(0, 0, 0, 0.5);
}

.submitButton--no-margin {
	margin: 0;
}

.submitButton--search {
	display: inline;
	float: right;
	padding: 8px 8px;
	background: $primary-white;
	border: none;
	cursor: pointer;
	position: relative;
	top: -6rem;
	left: -2px;

	i {
		color: $primary-black;
	}

	&:hover {
		background: $primary-white;
	}
}

.submitButton--search:disabled {
	background: $primary-white;
}

.company--AAG {
	.unauthorizedContainer {
		color: $aag-primary-blue;
	}
	.unauthorizedContainer .submitButton {
		background: $primary-yellow;
		color: darken($primary-yellow, 60);

		&:hover {
			// color: @primary-white;
			background: darken($primary-yellow, 20) !important;
		}
	}
}
