/* Variable definitions */
@import '../../styles/vars.scss';

.input-container {
	display: block;
	border-radius: 4px;
	margin: 0 0 1.5rem 0;
	position: relative;
	height: 4.5rem;
	padding: 0 1.5rem;
	border: 1px solid rgba(0, 0, 0, 0.15);
}
.inputFlex {
	display: grid;
	grid-template-columns: 1fr 24px;
	// align-items: center;
}
.eyeball {
	background: none;
	border: none;
	outline: none;
	appearance: none;
	color: rgba(255, 255, 255, 0.2);
	cursor: pointer;
}
.eyeball:hover {
	color: rgba(255, 255, 255, 0.4);
}
label.checkbox {
	display: block;
	border-radius: 4px;
	margin: 0 0 1.5rem 0;
	position: relative;
	height: 4.5rem;
	padding: 0;
	border: 1px solid rgba(0, 0, 0, 0.15);
	cursor: pointer;
	span {
		margin-left: 4.5rem;
		line-height: 47px;
		color: $primary-white;
		font-size: 1.4rem;
	}
	input {
		width: auto;
		position: absolute;
		top: 1.3rem;
		left: 1.5rem;
	}
}
label {
	display: block;
}
label .label {
	font-size: 1.4rem;
	position: absolute;
	line-height: 4.5rem;
	-webkit-transition: all 0.1s ease-out;
	-o-transition: all 0.1s ease-out;
	transition: all 0.1s ease-out;
	color: rgba(0, 0, 0, 0.65);
}
.input-container.input-focus .label,
.input-container.input-filled .label {
	top: 0px;
	font-size: 1.2rem;
	line-height: 2.4rem;
	transition: all 0.1s ease;
}

.required-hint:after {
	content: ' *';
	color: #ff0000;
	position: absolute;
	margin: 0px 0px 0px 80%;
	font-size: 1.2em;
	top: 0.5rem;
	right: 0.8rem;
}

select,
input {
	position: relative;
	background: none;
	border: none;
	outline: none;
}

select {
	top: 2.2rem;
	width: 100%;
	font-size: 1.6rem;
}

input {
	top: 2.2rem;
	width: 100%;
	font-size: 1.6rem;
}

.company--NAPA {
	.input-container.input-focus {
		border: 1px solid $napa-primary-blue;
	}
}

.company--AAG {
	.input-container.input-focus {
		border: 1px solid $aag-primary-blue;
	}
}
