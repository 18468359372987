@import 'styles/vars';

.noData__container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
    color: $primary-white;
    font-size: 2.4rem;

    .noData {
        text-align: center;
    }

}
a.nodataButton {
    text-decoration: none;
    display: inline-block;
    background: $primary-yellow;
    border-radius: 4px;
    padding: 1rem 3rem;
    font-size: 1.6rem;
    margin: 0 auto;
}
a.nodataButton:hover {
    background: darken($primary-yellow, 10);
}

.company--NAPA {
    a.nodataButton {
        color: $napa-primary-blue;
    }
}

.company--AAG {
    a.nodataButton {
        color: $aag-primary-blue;
    }
}