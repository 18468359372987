.errorContainer {
    background: lighten(red,40);
    border-radius: 4px;
    border: 1px solid lighten(red, 20);
    color: red;
    padding: 1rem 1rem 1rem 3rem;
    li {
        font-size: 1.6rem;
    }
}

.modifyButton {
    outline: none;
    appearance: none;
    border: none;
    background: rgba(0,0,0,0.2);
    border-radius: 4px;
    height: 36px;
    width: 36px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;

    i {
        color: rgba(255,255,255,0.8);
    }
}

.modifyButton:hover {
    background: rgba(0,0,0,0.4);
}