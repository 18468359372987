@import 'styles/vars';

nav {
    display: grid;
grid-template-columns: 1fr auto 1fr;
    a {
        text-decoration: none;
        border: none;
        margin: 0;
        padding: .7rem 1.5rem;
        background: none;
        line-height: 24px;
        color: $primary-white;
        border-radius: 4px;
        display: inline-block;
        font-size: 1.4rem;
        i {
            vertical-align: bottom;
            margin-right: 1rem;
        }
    }
    .nav-center {
        display: flex;
        justify-content: center;
    }
    .nav-right {
        display: flex;
        justify-content: end;
    }

}

header {

    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    padding: 2rem 0;

    .logo {
        height: 40px;
        text-align: center;
        img {
            height: 100%;
        }
    }
}

.company--NAPA {
    a:hover {
        background: darken($napa-primary-blue, 5);
    }

    a.active {
        background: darken($napa-primary-blue, 5);
    }
    header {
        background: $napa-dark-blue;
    }
}

.company--AAG {
    a.active {
        background: darken($aag-primary-blue, 5);
    }

    a:hover {
        background: darken($aag-primary-blue, 5);
    }

    header {
        background: #fff;
        box-shadow: 0px 3px 12px rgba(0,0,0,0.4);
    }
}