@import 'styles/vars';

.membershipHeading {
	text-align: center;
	font-size: 3rem;
}

.membershipHeadingCategory {
	text-align: center;
	margin-top: 3rem;
	margin-bottom: 3rem;

	div.membershipHeadingTitle {
		color: lighten($primary-blue, 50);
		text-transform: uppercase;
		display: grid;
		grid-template-columns: 1fr auto 1fr;
		place-items: center;
		gap: 1rem;
		font-size: 1.4rem;
		letter-spacing: 0.04em;
	}
	div.membershipHeadingTitle::before {
		content: '';
		height: 1px;
		width: 100%;
		background-image: linear-gradient(
			to left,
			lighten($primary-blue, 5),
			$primary-blue
		);
	}
	div.membershipHeadingTitle::after {
		content: '';
		height: 1px;
		width: 100%;
		background-image: linear-gradient(
			to right,
			lighten($primary-blue, 5),
			$primary-blue
		);
	}
}

.membership__button {
	border-radius: 4px;
	border: none;
	padding: 2rem;
	font-family: Arial;
	font-weight: bold;
	font-style: italic;
	font-size: 2.8rem;
	outline: none;
	appearance: none;
	cursor: pointer;
	display: grid;
	grid-template-rows: 1.2fr 1fr;
	box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.18),
		0 1px 3px 1px rgba(60, 64, 67, 0.26);
	transition: box-shadow 135ms cubic-bezier(0.4, 0, 0.2, 1),
		width 235ms cubic-bezier(0.4, 0, 0.2, 1);
	width: 100%;
	margin-bottom: 2rem;
}
.membership__button .program {
	div {
		color: #fff !important;
	}
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.membership__button .viewAccount {
	background: #eee;
	margin: 1.3rem -2rem -2rem -2rem;
	border-radius: 0 0 4px 4px;
	font-weight: normal;
	font-style: normal;
	font-size: 1.8rem;
	padding: 2rem 0;
	width: calc(100% + 4rem);
}

.membership__button:hover {
	box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
		0 4px 12px 1px rgba(60, 64, 67, 0.16);
	.viewAccount {
		background: #ccc !important;
	}
}

h1 {
	color: #eee;
	font-weight: normal;
}

.logoutButton {
	// position: absolute;
	// right: 0;
	appearance: none;
	outline: none;
	cursor: pointer;
	border: none;
	margin: 0;
	padding: 0.7rem 1.5rem;
	background: none;
	line-height: 24px;
	color: $primary-white;
	border-radius: 4px;
	i {
		vertical-align: bottom;
		margin-left: 1rem;
	}
}

.store-info {
	color: #fff;
	position: absolute;
	font-size: 1.4rem;
}

@media screen and (min-width: 64em) {
	.membership__button {
		flex: 1 1 0px;
		max-width: 360px;
		margin: 0 2rem;
	}
}

.contentContainer:not(.contentContainer--login) {
	margin-top: 200px;
}

nav {
	position: relative;
	top: 110px;
}

.store-info {
	top: 140px;
}

.storeWrapper {
	display: flex;
	justify-content: center;
	margin-top: 8rem;
}

.storeContainer {
	border-radius: 4px;
	padding: 2rem 4rem;
	text-align: center;

	.storeBusinessName {
		font-size: 2rem;
		margin: 0.5rem 0;
	}
}

.company--NAPA {
	.membership__button {
		background: lighten($napa-primary-blue, 10);

		div {
			color: $napa-primary-blue;
		}
	}

	.membership__button:hover {
		background: lighten($napa-primary-blue, 5);
	}

	.logoutButton:hover {
		background: darken($napa-primary-blue, 5);
	}

	.storeContainer {
		background: darken($napa-primary-blue, 10);
	}
}

.company--AAG {
	.membership__button {
		background: lighten($aag-primary-blue, 10);

		div {
			color: $aag-primary-blue;
		}
	}

	.membership__button:hover {
		background: lighten($aag-primary-blue, 5);
	}

	.logoutButton:hover {
		background: darken($aag-primary-blue, 5);
	}

	.storeContainer {
		background: darken($aag-primary-blue, 10);
	}
}
